/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 80번째 로그에서는 팬데믹 이후의 업무 환경, 아이폰 SE 2세대, 베어 에디터 2.0 알파 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "팬데미 이후의 업무 환경 / 시각화 / 접지"), "\n", React.createElement(_components.p, null, "깃랩"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.lllkkdti.com/"
  }, "르르르 꼰대 성향 검사 (KKDTI)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.edwardtufte.com/tufte/"
  }, "The Work of Edward Tufte and Graphics Press")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/raccoonyy/status/1250423210408964104"
  }, "seungho kim on Twitter: \"판세 분석에 지도를 사용하면 지역 크기 때문에 시각적인 왜곡이 발생하는데, 이렇게 목록화하니까 더 괜찮아 보인다.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://openlook.org/wp/does-lotteria-locate-different/"
  }, "터미널엔 롯데리아, 동네에 맥도날드, 강남에 버거킹..? – OpenLook")), "\n"), "\n", React.createElement(_components.h3, null, "아이폰 SE 2세대"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/iphone-se/"
  }, "iPhone SE - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/ipad-keyboards/"
  }, "iPad Keyboards - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/ko-kr/HT210380"
  }, "Sidecar 기능을 이용하여 iPad를 Mac의 보조 디스플레이로 사용하기 - Apple 지원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://astropad.com/"
  }, "Astropad")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.duetdisplay.com/"
  }, "Duet Display")), "\n"), "\n", React.createElement(_components.h3, null, "베어(Bear) 앱 에디터 2.0 알파"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/alpha/"
  }, "Alpha Page | Bear's Editor Future Evolutions | Bear App")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
